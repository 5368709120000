import React from "react";

const Features = () => {
  return (
    <section id="features" className="container">
      <span className="section-caption">Daysketcher Features</span>
      <h2>Features</h2>
      <p className="text-center mb-5">
        Join us on an innovative feature journey as we craft
        <br /> Daysketcher, designed to revolutionize journaling.
      </p>
      <div className="grid position-relative">
        <div className="g-col-md-4 g-col-12 step">
          <div className="step-header">
            <span className="step-header-number mx-md-auto">1</span>
            <div className="step-header-details">
              <h3 className="step-header-title">Journaling</h3>
              <p>Make it a habit!</p>
            </div>
          </div>

          <div className="vstack step-body">
            <div className="card-body">
              <h4 className="card-title">Daily Journal</h4>
              <p className="card-text">
                Capture your daily moments, thoughts, and memories in a private
                digital diary.
              </p>
            </div>
            <hr className="border border-1 opacity-50" aria-hidden="true" />
            <div className="card-body">
              <h4 className="card-title">Express Yourself</h4>
              <p className="card-text">
                Your judgment-free space to freely express thoughts and
                emotions.
              </p>
            </div>
            <hr className="border border-1 opacity-50" aria-hidden="true" />
            <div className="card-body">
              <h4 className="card-title">Mood Tracking</h4>
              <p className="card-text">
                Understand your moods, track emotions, and improve your
                well-being.
              </p>
            </div>
          </div>
        </div>
        <div className="g-col-md-4 g-col-12 step">
          <div className="step-header">
            <span className="step-header-number mx-md-auto">2</span>
            <div className="step-header-details">
              <h3 className="step-header-title">Mood Booster</h3>
              <p>Get inspired and motivated!</p>
            </div>
          </div>

          <div className="vstack step-body">
            <div className="card-body">
              <h4 className="card-title">Mood Booster</h4>
              <p className="card-text">
                Elevate your mood by choosing your emotional state, sharing your
                thoughts, and reliving cherished memories that bring positivity
                and gratitude to your day.
              </p>
            </div>
            <hr className="border border-1 opacity-50" aria-hidden="true" />
            <div className="card-body">
              <h4 className="card-title">Customized Diary</h4>
              <p className="card-text">
                Choose a topic to write about or create your own template.
              </p>
            </div>
          </div>
        </div>
        <div className="g-col-md-4 g-col-12 step">
          <div className="step-header">
            <span className="step-header-number mx-md-auto">3</span>
            <div className="step-header-details">
              <h3 className="step-header-title">
                Collect and Pair memories from anywhere
              </h3>
            </div>
          </div>

          <div className="vstack step-body">
            <div className="card-body">
              <h4 className="card-title">AI enhanced matching memories</h4>
              <p className="card-text">
                Matching past positive memories to your current emotion.
              </p>
            </div>
            <hr className="border border-1 opacity-50" aria-hidden="true" />
            <div className="card-body">
              <h4 className="card-title">Transition to digital journaling</h4>
              <p className="card-text">
                Say goodbye to physical limitations and embark on a new
                journaling journey. Effortlessly digitize your handwritten
                memories and access them anytime, anywhere.
              </p>
            </div>
            <hr className="border border-1 opacity-50" aria-hidden="true" />
            <div className="card-body">
              <h4 className="card-title">
                And many more improvements and features...
              </h4>
            </div>
          </div>
        </div>
        <hr className="g-col-8 g-start-3 step-line" />
      </div>
    </section>
  );
};

export default Features;
