import { useState, useEffect } from "react";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import SceneInit from "./SceneInit";

const Globe = () => {
  const [play, setPlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePlay = (play) => {
    setIsLoading(true);
    setPlay(play);
  };

  useEffect(() => {
    const daysketcherScene = new SceneInit("myThreeJsCanvas");
    daysketcherScene.initialize();
    daysketcherScene.animate();

    daysketcherScene.scene.background = new THREE.Color("skyblue");

    let loadedModel;
    const glftLoader = new GLTFLoader();
    glftLoader.load(
      "./DaySketcherGlobe_GLTF/PlanetFull_Paint5.gltf",
      (gltfScene) => {
        loadedModel = gltfScene;
        gltfScene.scene.rotation.x = Math.PI / 2;
        gltfScene.scene.rotation.y = Math.PI / 4;
        gltfScene.scene.position.y = -7;
        gltfScene.scene.rotation.y = 9;
        gltfScene.scene.rotation.x = -5.5;
        gltfScene.scene.scale.set(35, 35, 35);
        daysketcherScene.scene.add(gltfScene.scene);
      }
    );

    const animatePlanet = (play = false) => {
      if (play && loadedModel) {
        loadedModel.scene.rotation.y -= 0.003;
      }
      requestAnimationFrame(() => animatePlanet(play));
    };

    if (play) {
      setIsLoading(false);
      animatePlanet(play);
    }
    setIsLoading(false);
  }, [play]);

  return (
    <div className="g-col-md-10 g-col-12 g-start-md-2 position-relative">
      <div className="button-set">
        {play ? (
          <button
            className="btn btn-dark"
            onClick={() => handlePlay(false)}
            aria-label="Pause"
          >
            <span className="fa fa-pause"></span> Pause
          </button>
        ) : (
          <button
            className="btn btn-dark"
            onClick={() => handlePlay(true)}
            aria-label="Play"
          >
            <span className="fa fa-play"></span> Play
          </button>
        )}
      </div>

      {isLoading ? (
        <div className="block-ui">
          <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}

      <div className="canvas-wrapper">
        <canvas
          id="myThreeJsCanvas"
          aria-labelledby="3D representation of an earth with memories like photos and videos showed by location"
        />
      </div>
    </div>
  );
};

export default Globe;
