import React, { useState, useRef } from "react";
import axios from "axios";
import * as bootstrap from "bootstrap";
import { get, child, update, push, ref } from "firebase/database";
import { dbRef, analytics, database as db } from "../../firebase";

import "./Registration.scss";

const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Registration = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "There hase been an error! Please try again later."
  );
  const [toastIcon, setToasticon] = useState("triangle-exclamation");
  const [forceButtonDisable, setForceButtonDisable] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const toastRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsEmailValid(
      !!(e.target.value.length === 0 || e.target.value.match(VALID_EMAIL_REGEX))
    );
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const form = formRef.current as unknown as HTMLFormElement;
    if (isEmailValid) {
      let emailArleadyExists = false;

      get(child(dbRef, `emails`)).then((snapshot) => {
        if (snapshot.exists()) {
          emailArleadyExists = Object.values(snapshot.val()).some((mail) => {
            return mail == email;
          });
        }

        if (emailArleadyExists) {
          handleToastMessage(
            "The provided email has already been submitted",
            "info",
            "info"
          );
          form.blur();
          form.reset();
        } else {
          const newPostRef = push(ref(db, "emails")).key;
          const updates: { [key: string]: string } = {};

          updates["/emails/" + newPostRef] = email;
          update(ref(db), updates)
            .then(() => {
              setForceButtonDisable(true);
              handleMailSend(email)
                .then(() => {
                  handleToastMessage(
                    "Thank you for subscribing! Check your mailbox to get started!",
                    "check",
                    "success"
                  );
                  form.reset();
                  setEmail("");
                  setIsEmailValid(false);
                  setInputTouched(false);
                  setForceButtonDisable(false);
                })
                .catch(() => {
                  handleToastMessage(
                    "There has been an error! Please try again.",
                    "triangle-exclamation",
                    "error"
                  );
                  setForceButtonDisable(false);
                });
            })
            .catch(() => {
              handleToastMessage(
                "There has been an error! Please try again.",
                "triangle-exclamation",
                "error"
              );
            });
          setForceButtonDisable(false);
        }
        form.blur();
        form.reset();
      });
    } else {
      form.focus();
    }
  };

  const handleBlur = () => {
    setInputTouched(true);
    const input = inputRef.current
      ? (inputRef.current as HTMLInputElement)
      : null;
    if (input) {
      input.classList.remove("not-valid");
      if (!isEmailValid) input.classList.add("not-valid");
    }
  };

  const handleToastMessage = (
    message: string,
    icon: string,
    className: string
  ) => {
    const toast = toastRef.current ? toastRef.current : null;

    if (toast) {
      toast.classList.remove("toast-info", "toast-success", "toast-error");

      bootstrap.Toast.getOrCreateInstance(toast, { autohide: true }).show();
      setToastMessage(message);
      setToasticon(icon);
      toast?.classList.add(`toast-${className}`);
    }
  };

  const handleMailSend = (email: string) => {
    const { REACT_APP_BASE_API_URL, REACT_APP_SUBSCRIBE_API_URL } = process.env;
    return axios.post(
      `${REACT_APP_BASE_API_URL}${REACT_APP_SUBSCRIBE_API_URL}=${email}`
    );
  };

  return (
    <section id="registration" className="constainer-fluid">
      <img
        src="assets/images/daysketcher-logo-white.svg"
        alt="Daysketcher Logo"
        width="100%"
        height="32px"
      />
      <h2 className="text-white mt-4 mb-3">
        Want to start reflecting on your memories today?
      </h2>
      <div className="col-md-6 col-12 mx-md-auto px-3">
        <p className="text-white text-center">
          Join now for our free beta app experience, featuring digital
          journaling and positive memory reflections! As an early participant,
          you'll enjoy a 6 months free complimentary subscription.
        </p>
        <form
          ref={formRef}
          name="form"
          className="needs-validation"
          id="subscriptionForm"
          onSubmit={handleSubmit}
        >
          <div className="form-group mt-5 text-center">
            <label htmlFor="email" className="form-label text-center">
              Subscribe for early bird access
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              className="form-control"
              aria-describedby="invalidMessage"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              ref={inputRef}
              required
            />
            <button
              type="submit"
              id="submit"
              className="btn btn-dark mt-2"
              disabled={
                !isEmailValid || email.length === 0 || forceButtonDisable
              }
            >
              {forceButtonDisable && (
                <i
                  className="fa-solid fa-spinner fa-spin me-2"
                  aria-label="Loading Spinner"
                ></i>
              )}
              <span>Subscribe</span>
            </button>
          </div>
          {!isEmailValid && inputTouched ? (
            <small className="email-invalid text-danger" id="invalidMessage">
              <i className="fa-solid fa-triangle-exclamation"></i>
              <span className="ms-2">Please enter a valid email address.</span>
            </small>
          ) : null}
          <small className="pt-4 text-white text-center d-block">
            *By subscribing, I consent to Daysketcher processing my email address
            for the purpose of sending a newsletter. You can unsubscribe at any
            time. For more information check our <span>
            <a
              href="DaysketcherPrivacyPolicy.html"
              target="_blank"
              className="text-white"
            >
              Privacy Policy.
            </a></span>
          </small>
          <div className="toast-container position-fixed bottom-0 end-0 mb-5 p-3">
            <div
              id="live-toast"
              className="toast toast-info"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              ref={toastRef}
            >
              <div
                id="toast-body"
                className="toast-body d-flex gap-3 align-items-center"
              >
                <i className={`fa fa-2x fa-${toastIcon}`}></i>

                <p id="toastMessage" className="mb-0">
                  {toastMessage}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Registration;
